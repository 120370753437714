import React from "react"
import { Form } from "@ant-design/compatible"
import "@ant-design/compatible/assets/index.css"
import { Alert, Button, Col, Row, Spin, Typography } from "antd"
import { ActiveCampaignValues, Email, Name, Submit, UTMParams } from "./Fields"
import API from "../../utils/api"

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

class OptInModal extends React.Component {
  state = {
    formSubmitted: false,
    formSuccess: false,
  }

  componentDidMount() {
    this.props.form.validateFields()
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({ formSubmitted: true })
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const query = `mutation {
          triggerActiveCampaignAutomations(
            form_name:"${values.form_name}",
            automation_id:"${values.automation_id}",
            sync_deal:"false",
            utm_medium:"${values.utm_medium}",
            utm_source:"${values.utm_source}",
            utm_campaign:"${values.utm_campaign}",
            email:"${values.email}",
            name:"${values.name}"
          ){
            error
            result
          }
        }`
        const res = await API.post("", {
          query: query,
        })
        if (res.status !== 404) {
          this.setState({ formSuccess: true })
        }
      }
    })
  }

  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = this.props.form
    const nameError = isFieldTouched("name") && getFieldError("name")
    const emailError = isFieldTouched("email") && getFieldError("email")

    return (
      <>
        {!this.state.formSuccess ? (
          <Spin spinning={this.state.formSubmitted && !this.state.formSuccess}>
            <Form
              onSubmit={this.handleSubmit}
              style={{ textAlign: `left`, width: `100%` }}
            >
              <ActiveCampaignValues
                automationId={this.props.automationId}
                decorator={getFieldDecorator}
                formName={this.props.formName}
              />
              <UTMParams decorator={getFieldDecorator} />
              <Name decorator={getFieldDecorator} validator={nameError} />
              <Email decorator={getFieldDecorator} validator={emailError} />
              {this.props.secondaryButtonText ? (
                <Button size="large" type="ghost">
                  {this.props.secondaryButtonText || "Cancel"}
                </Button>
              ) : (
                <></>
              )}
              <Row align="middle" gutter={16} justify="top" type="flex">
                <Col span={12}>
                  <Form.Item>
                    <Button
                      block
                      onClick={this.props.toggleModal}
                      size="large"
                      type="ghost"
                    >
                      {this.props.cancelText || "Close"}
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Submit
                    id={this.props.gtmClickId}
                    disabled={hasErrors(getFieldsError())}
                    text={this.props.submitText || "Submit"}
                  />
                </Col>
              </Row>
            </Form>
          </Spin>
        ) : (
          <Row align="middle" justify="center" type="flex">
            <Col span={24}>
              <Alert
                description={
                  <Typography.Paragraph>
                    Thanks for signing up! We'll send you our best offers as
                    soon as they become available.
                  </Typography.Paragraph>
                }
                message={`Got it!`}
                onClose={this.props.toggleModal}
                type="success"
                showIcon
              />
            </Col>
            <Col xs={24} md={18} style={{ margin: `24px auto` }}>
              <Button
                block
                onClick={this.props.toggleModal}
                size="large"
                type="ghost"
              >
                Close Window
              </Button>
            </Col>
          </Row>
        )}
      </>
    )
  }
}

export default Form.create({ name: "opt_in_form" })(OptInModal)
